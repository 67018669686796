import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import moment from 'moment';

import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { PRICE_SHOWN } from '@/modules/rates/constants';

import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesPriceHistoryStore from '@/modules/rates/price-history/store/rates-price-history.store';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from './rates-price-history-common.service';
import RatesPriceHistoryModel from './models/rates-price-history.model';

export type RoomDictionary = { [hotelId: number]: RatesDocumentItemModel };

interface RatesPriceHistoryPublicInterface {
    /**
     * Sets document key that will be used to get data from specific document
     * Initial value - `main`
     */
    setDataKey(key: string): void;

    /**
     * Returns dictionary with hotels and their rooms for the specified day
     *
     * @param day - which history day (from the `lastScanDate`) the data should be taken from
     * @param diffDays - **For Compare Mode Only** - days ago from the `day` param
     */
    getSuitableRoomByDay(day: Day, diffDays: number): RoomDictionary;

    /**
     * Returns price history for specified document
     *
     * @param day - month day
     * @param hotelId - main hotel
     * @param compsetId - compset id
     * @param documentFilters - document filters
     */
    getPriceHistory(
        day: Day | null,
        hotelId: number | null,
        compsetId: string | null,
        documentFilters: DocumentFiltersModel | null
    ): RoomDictionary[] | null;

    /**
     * Returns los restriction from the history document
     */
    getLosRestriction(day: Day | number, hotelId: number | null): number;

    /**
     * Returns price list for all days of history document
     */
    getPriceHistoryHotelPrices(hotelId: number): (number | null)[];

    /**
     * Returns demand value of the current document
     */
    getDemand(): number | null;

    /**
     * Returns occupancy value of the current document
     */
    getOccupancy(): number | null;

    /**
     * @readonly
     * Returns true if there's no data for the current document
     */
    readonly isNoData: boolean;

    /**
     * Global rates settings
     */
    ratesSettings: RatesSettingsModel | null;

    /**
     * Choosen currency
     */
    currency: string | null;

    /**
     * Last scan date
     */
    readonly lastScanDate: Date | null;

    /**
     * Hotel ids list
     */
    readonly hotels: number[] | null;
}

export const RatesPriceHistoryServiceS = Symbol.for('RatesPriceHistoryServiceS');
@injectable(RatesPriceHistoryServiceS as unknown as string)
export default class RatesPriceHistoryService implements Stateable, RatesPriceHistoryPublicInterface {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    currentDataKey: string = 'main';

    get isNoData() {
        const { trendData } = this.documents.main || {};

        return trendData
            ? Object.keys(trendData).length === 0
            : true;
    }

    set ratesSettings(value: RatesSettingsModel| null) {
        this.storeState.ratesSettings = value;
    }

    get ratesSettings() {
        return this.storeState.ratesSettings;
    }

    get currentDocument() {
        return this.documents[this.currentDataKey] as RatesPriceHistoryModel | undefined;
    }

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    set currency(value: string | null) {
        this.ratesPriceHistoryCommonService.currency = value;
    }

    get lastScanDate() {
        return this.storeState.lastScanDate;
    }

    get selectedTrendDate() {
        const { dayIndex } = this.storeState;

        if (!this.lastScanDate) {
            return null;
        }

        const d = new Date(this.lastScanDate);
        d.setDate(d.getDate() - dayIndex);

        return d;
    }

    get settingsByKey() {
        return this.currentDataKey === 'main'
            ? this.ratesSettings
            : this.ratesAnalysisService.settings;
    }

    get hotels() {
        let hotelIds: number[] = [];
        const { competitors, hotelId } = this.ratesPriceHistoryCommonService;

        if (competitors && hotelId) {
            const competitorsFilters = this.documentFiltersService.competitors;

            const filteredCompetitors = competitorsFilters
                ? competitors.filter((value: number) => competitorsFilters.includes(value))
                : competitors;

            hotelIds = [...filteredCompetitors, hotelId];
        }

        return hotelIds.length ? hotelIds : null;
    }

    get isNetTotalAvailable() {
        const { compsetId, documentFilters } = this.ratesPriceHistoryCommonService;
        const { docDay, hotelId } = this.ratesPriceHistoryCommonService;

        this.setDataKey('main');

        const priceHistory = docDay
            ? this.getPriceHistory(docDay as Day, hotelId, compsetId, documentFilters)
            : null;

        let isAvailable = true;

        if (priceHistory) {
            const dates = Object.keys(priceHistory).map(day => +day);

            isAvailable = !!dates.find(day => !_.isEmpty(priceHistory[day]));
        } else {
            isAvailable = false;
        }

        return isAvailable;
    }

    private set documents(value) {
        this.storeState.documents = value;
    }

    private get documents() {
        return this.storeState.documents;
    }

    private getDateByHistoryDay(day: number) {
        if (!this.lastScanDate) return null;

        const dateInstance = new Date(this.lastScanDate);

        const isTimeNormalized = dateInstance.toISOString().includes('00:00:00.000Z');

        if (!isTimeNormalized) {
            dateInstance.setMinutes(dateInstance.getMinutes() - dateInstance.getTimezoneOffset());
        }

        dateInstance.setDate(dateInstance.getDate() - day);

        return moment(dateInstance).format('DD-MM-YYYY');
    }

    private getHotelByDay(dayParam: Day | number) {
        const filteredHotels = {} as {[hotelId: number]: RatesDocumentItemModel};

        if (!this.hotels) {
            return filteredHotels;
        }

        if (!this.documents || !this.documents[this.currentDataKey] || !this.lastScanDate) {
            return filteredHotels;
        }

        const dateInstance = new Date(
            this.lastScanDate.getFullYear(),
            this.lastScanDate.getMonth(),
            this.lastScanDate.getDate() - dayParam,
        );

        const day = moment(dateInstance).format('DD');
        const month = moment(dateInstance).format('MM');
        const year = moment(dateInstance).format('YYYY');
        // @ts-ignore
        return this.documents[this.currentDataKey].trendData[`${day}-${month}-${year}`];
    }

    private getPriceHistoryHotelData(hotelIdParam: number) {
        const {
            docDay,
            hotelId,
            compsetId,
            documentFilters,
        } = this.ratesPriceHistoryCommonService;

        const priceHistory = docDay
            ? this.getPriceHistory(
                docDay as Day,
                hotelId,
                compsetId,
                documentFilters,
            ) as {[hotelIdParam: number]: RatesDocumentItemModel}[]
            : null;

        return this.getPriceHistoryMap(priceHistory, hotelIdParam);
    }

    private getPriceHistoryMap(priceHistory: {[p: number]: RatesDocumentItemModel}[] | null, hotelId: number) {
        return priceHistory
            ? priceHistory.map((day: {[hotelId: number]: RatesDocumentItemModel} | null) => {
                if (!day || !Object.keys(day).length) {
                    return null;
                }

                const hotelRoom = day[Number(hotelId)];

                if (!hotelRoom) {
                    return null;
                }

                return day[Number(hotelId)];
            })
            : [];
    }

    setDataKey(value: string) {
        this.currentDataKey = value;
        return this;
    }

    getSuitableRoomByDay(dayParam: Day | number, diffDays = 0): RoomDictionary {
        const { documents, hotels: hotelList, lastScanDate } = this;
        const EMPTY_DICTIONARY = {} as RoomDictionary;

        if (!this.currentDataKey) {
            this.currentDataKey = 'main';
        }

        const isParametersNotValid = false
            || !hotelList
            || !documents
            || !documents[this.currentDataKey]
            || !lastScanDate;

        if (isParametersNotValid) {
            return EMPTY_DICTIONARY;
        }

        const { trendData } = documents[this.currentDataKey]!;

        if (!trendData) {
            return EMPTY_DICTIONARY;
        }

        const date = this.getDateByHistoryDay(dayParam + diffDays)!;
        const data = trendData[date];

        if (!data) {
            if (diffDays && diffDays < 14) {
                return this.getSuitableRoomByDay(dayParam, diffDays + 1);
            }

            return EMPTY_DICTIONARY;
        }

        let hotels = {};

        if (this.ratesPriceHistoryCommonService.isAllChannels) {
            hotels = data;
        } else {
            ({ hotels } = data);
        }

        const allHotels = this.ratesCommonService.getAllRoomsByHotels(hotels);

        const byValidRooms = ([__, room]: any) => !!room;

        const filteredHotelsEntries = Object
            .entries(allHotels)
            .map(([hotelId, room]) => {
                const hid = +hotelId || hotelId;
                const isHotelExists = true
                    && this.hotels
                    && this.hotels.includes((hid) as number);

                if (isHotelExists) {
                    return [hid, room];
                }

                return [-1, null];
            })
            .filter(byValidRooms);

        const filteredHotels = Object.fromEntries(filteredHotelsEntries);

        return filteredHotels as RoomDictionary;
    }

    getPriceHistory(
        actualDay: Day | null,
        hotelId: number | null,
        compsetId: string | null = null,
        documentFilters: DocumentFiltersModel | null = null,
    ): RoomDictionary[] | null {
        const EMPTY_DICTIONARY = {} as RoomDictionary;
        const { sortedDaysList } = this.ratesPriceHistoryCommonService;
        const toSuitableRoom = (__: any, index: number) => this.getSuitableRoomByDay(index) || EMPTY_DICTIONARY;

        this.ratesPriceHistoryCommonService
            .setData(actualDay, hotelId, compsetId, documentFilters);

        return sortedDaysList.map(toSuitableRoom);
    }

    getLosRestriction(dayParam: Day | number, hotelId: number) {
        const data: any = this.getHotelByDay(dayParam);
        if (!data || !data.hotels || !data.hotels[hotelId]) {
            return false;
        }
        return data.hotels[hotelId].losRestriction;
    }

    getPriceHistoryHotelPrices(hotelId: number, priceShown?: PRICE_SHOWN) {
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;
        return this.getPriceHistoryHotelData(hotelId)
            .map(room => (room ? (this.ratesCommonService.switchPrice(room, actualPriceShown) || null) : null))
            .reverse();
    }

    getDemand() {
        if (!this.selectedTrendDate || !this.documents.main) {
            return null;
        }

        const iso = moment(this.selectedTrendDate).format('DD-MM-YYYY');
        const data = this.documents.main.trendData[iso];

        if (!data || data.demand === null) {
            return null;
        }

        return data.demand as number;
    }

    getOccupancy() {
        if (!this.selectedTrendDate || !this.documents.main) {
            return null;
        }

        const iso = moment(this.selectedTrendDate).format('DD-MM-YYYY');
        const data = this.documents.main.trendData[iso];

        if (!data || data.occupancy === null) {
            return null;
        }

        return data.occupancy as number;
    }
}
